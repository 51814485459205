import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Publication from "../components/publication"

const PublicationsPage = ({data}) => (
  <Layout>
    <Seo title="Publications" description="You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk." />
    <div className="max-w-screen-md mx-auto px-4 relative z-20 mt-20 pb-96">
      <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Official Publications</h2>
      <p className="text-2xl mt-4 text-center">Take the chance and get the latest updates of the project.</p>
      <div className="mt-24">
        {data.allPrismicPublications.edges.map((edge) => (
            <Publication 
              key={edge.node.id}
              date={edge.node.data.date}
              title={edge.node.data.title.text}
              url={edge.node.data.file.url}
              className="mt-6 first:mt-0" 
              category={edge.node.data.category}
            />
          )
        )}
      </div>
    </div>
  </Layout>
)

PublicationsPage.propTypes = {
  data: PropTypes.shape({
    allPrismicPublications: PropTypes.object.isRequired,
  }).isRequired,
}

export const query = graphql`
  query PublicationsPage {
    allPrismicPublications {
      edges {
        node {
          id
          data {
            date(formatString: "DD.MM.YYYY")
            title {
              text
            }
            file {
              url
            }
            category
          }
        }
      }
    }
  }`

export default PublicationsPage




/*
import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Event from "../components/event"

const EventsPage = ({data}) => {

  return (
    <Layout>
      <Seo title="Events" description="You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk." />
      <div className="max-w-screen-md mx-auto px-4 relative z-20 mt-20 pb-96">
        <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Events</h2>
        <p className="text-2xl mt-4 text-center">You wanna join the ECO-Qube project or have any questions? So feel free to contact us and let's talk.</p>
        {data.allPrismicEvents.edges.map((edge) => (
          <Event
            key={edge.node.id}
            location={edge.node.data.location}
            title={edge.node.data.title.text}
            text={edge.node.data.text}
            link={edge.node.data.join_now_link}
            startDate={edge.node.data.from}
            />
          )
        )}
      </div>
    </Layout>
  )
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    allPrismicEvents: PropTypes.object.isRequired,
  }).isRequired,
}


export const query = graphql`
  query EventsPage {
    allPrismicEvents(filter: {isFuture: {eq: true}}, sort: {fields: data___from, order: ASC}) {
      edges {
        node {
          id
          data {
            from(formatString: "DD.MM.YYYY - HH:mm")
            location
            join_now_link {
              target
              url
            }
            text {
              html
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`

export default EventsPage
*/
